<template>
<div class="item">
<el-col :span="24"   v-if="activityType==1">
  <!-- 预售专区 -->
   <div class="imgWrap"  @click="jumpDetail(info)">
        <!-- <img v-if="info.quantity<Number(info.minOrderNum)" class="soldOut" alt="补货中" src="@/assets/soldOut.png" /> -->
        <img
          :alt="info.name | defaultName"
          :src="url?url + picSuffix : defaultImage"
        />
      </div>
      <h3 class="shopName" :title="info.name">
        {{ info.name | defaultName }}
        <span  v-if="info.presellSubTitle">[{{info.presellSubTitle}}]</span>
      </h3>
      <p class="msg"><strong>{{info.specification}}</strong> <strong>{{info.partSpecification}}</strong></p>
       <span class="AddBtn"  @click="jumpDetail(info)">立即购买</span>
      <div class="priceBox">
        <span class="price">
          {{ info.auditUserStatus===0?"***":info.price  | capitalize }}
        </span>
        </div>
      <div class="priceBox">
        <del class="delPrice">
           {{ info.auditUserStatus===0?"***":info.originalPrice  | capitalize }}
          </del>
      </div>
  </el-col>
<el-col :span="24"   v-else>
    <!-- 限时抢购 -->
   <div class="imgWrap"  @click="jumpDetail(info)">
         <img
            :alt="info.name | defaultName"
            :src="info.images?info.images + picSuffix : defaultImage"
          />
      </div>
      <h3 class="shopName" :title="info.name">
        {{ info.name | defaultName }}
        <span  v-if="info.presellSubTitle">[{{info.presellSubTitle}}]</span>
      </h3>
      <p class="msg">每人限购{{info.comboUserTotal}}次</p>
      <el-progress :percentage="percentage" :format="format"></el-progress>
       <span class="AddBtn"  @click="jumpDetail(info)">立即购买</span>
      <div class="priceBox">
        <span class="price">
          {{ info.auditUserStatus===0?"***":info.comboPrice  | capitalize }}
        </span>
        </div>
    <p class="msg" v-if="new Date(this.info.beginDate).getTime()<new Date().getTime()">进行中</p>
    <p class="msg"  v-else>{{info.beginDate}}开抢</p>
      <div class="priceBox">
        <del class="delPrice">
           {{ info.auditUserStatus===0?"***":info.comboOriginalPrice  | capitalize }}
          </del>
      </div>
  </el-col>
</div>
  
</template>

<style lang="less" scoped>
@import "~style/index.less";
/deep/.el-progress-bar{
         padding-right: 90px;
    margin-right: -90px;
}
/deep/.el-progress-bar__inner{
  background-color: #E00B0B;
}
/deep/.el-progress__text{
      margin-left: 6px;
    line-height: 20px;
    float: right;
}
.item {
  // width: 228px;
  width: 212px;
  background: #fff;
  float: left;
  margin-top: 15px;
  margin-right: 15px;
  padding-left:8px;
  padding-right:8px;
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
    .AddBtn{
      position: absolute;
      bottom:12px;
      right:10px;
      width: 80px;
      text-align: center;
      padding:5px 0;
      font-size: 14px;
      color: #000;
      background: #FFD303;
      
    }
  &:hover{
   box-shadow: 10px 6px 6px -4px #dedada;
		border-radius:7px;
  }

  .imgWrap {
    padding-top: 15px;
    overflow: hidden;
    position: relative;
    .soldOut{
       display: block;
        width:212px;
      height:212px;
       z-index: 2;
       position:absolute;
       top:10px;
       left:0px;
    }
    img {
      // display: inline-block;
      // width: 100%;
      // height: 150px;
      display: block;
      width: 212px;
      height:212px;
      margin: 0 auto;
    }
  }
  .shopName {
    margin-top:10px;
    height: 22px;
    color: #333;
    font-size:15px;
    line-height: 22px;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span{
      font-weight: normal;
       font-size:13px;
    }
  }
   .priceBox {
    margin-top:5px;
    height:26px;
    line-height:26px;
    font-size: 12px;
    color: #999;
    .price{
        color: #f43c38;
        font-size:16px;
        font-weight: bold;
        display: inline-block;
    }
    .delPrice{
        // padding-left:0px;
        color: #929598;
        font-size: 12px;
    }
  }
  .msg{
        color: #929598;
        font-size: 12px;
        text-indent: 3px;
        line-height: 20px;
    }
  .specification {
    height:20px;
    line-height:20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
      color: #999;
    
  }
  .validity {
    line-height:20px;
     font-size: 12px;
     color: #999;
  }
  .hidePrice {
    color: #f43c38;
    line-height:26px;
    padding-top:6px;
    .price {
      color: #ff3333;
      font-size:18px;
      line-height:26px;
          font-weight: bold;
    }
     .bottomBox{
       float: right;
       font-size: 12px;
       color: #ff3333;
    }
  }
}
</style>

<script>
import defaultImage from "@/assets/defaultImage.png";
export default {
  name: "ActivityModuleItem",
  data() {
    return {
      picSuffix:localStorage.getItem("productPic"),
      currentIndex: 0,
      //价格框显示隐藏
      num:0,
      defaultImage:defaultImage,
      activityType:'',
      url:'',
      percentage:0
    };
  },
  props: {
    info: {
      type: Object,
    },
    ActivityType:{
      type:Number
    }
  },
   //监听执行
  watch: {
    "info": function(newVal) {
      if(newVal){
       if(this.ActivityType==1){
            //预售
            this.url=this.info.pic.split(",")[0]
          }else{
            //限时购   套餐使用數量comboUsage /套餐总量comboTotal
              if(this.info.comboTotal==0){
                  this.percentage=Number(0)
              }else{
                  this.percentage=parseFloat((this.info.comboUsage / this.info.comboTotal*100).toFixed(2))
              }
          }
      }
    },
  },
  methods: {
    format(percentage) {
        return  `已售${percentage}%`;
      },
   jumpDetail(info) {
     if(this.ActivityType==1){
       //预售
      //  alert('预售')
        this.$router.push({
         name: "ActivityModuleDetail",
         query: { promotionId:info.promotionId,ActivityType:1},
      });
     }else{
       //限时抢购
      //  alert(info.id)
        //  console.log('限时抢购限时抢购',info)
        this.$router.push({
         name: "ActivityModuleDetail",
         query: { id: info.id,ActivityType:2},
      });
     }
     
    },
  },
  computed: {

  },

  created() {
   this.activityType=this.ActivityType
    // console.log(this.info)
    if(this.ActivityType==1){
      //预售
      this.url=this.info.pic.split(",")[0]
    }else{
      //限时购   套餐使用數量comboUsage /套餐总量comboTotal
        if(this.info.comboTotal==0){
            this.percentage=Number(0)
        }else{
            this.percentage=parseFloat((this.info.comboUsage / this.info.comboTotal*100).toFixed(2))
        }
    }
  },
  updated() {
  },
};
</script>
